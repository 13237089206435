import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Legal" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-30">
        <div class="format_text">
          <h1>Legal</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_7661599-Legal-300x199.jpeg"
                alt="Pen on book"
              />
            </span>
            Larry’s first drafting of an offering prospectus was many years ago,
            when he served as Chairman of OZMA Corporation, a Washington,
            DC-based financial services entity operating in the mortgage banking
            industry. During the following years, he has spent thousands of
            hours involved in the drafting of various corporate, legal, and
            securities disclosure documents for many of the various entities he
            has been associated with or for investment banking clients.
          </p>
          <p>
            Law school afforded him additional skills in the areas of agency and
            partnership, corporations, mergers and acquisitions, oil and gas,
            and securities regulations. He is author of numerous articles on the
            topics of law, finance and corporate governance. During 2007, he
            studied law at Nankai University, Tianjin, China and has maintained
            a particular interest in Chinese securities markets and securities
            issuers who conduct most or all of their business in China. He
            studied law at the University of Buenos Aires, Argentina (2008).
            During recent years he has returned to university teaching,
            particularly constitutional law, business law and ethics,
            international business law, and corporate governance.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
